import { Avatar, Box, Typography } from "@mui/material";
import {styled} from "@mui/material/styles";
import { Colors } from "../theme";


const AboutBox = styled(Box) (() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2rem 2rem 0rem 2rem',
    background: Colors.dark,
    flexWrap: 'wrap',
    borderRadius: '5px',
    justifyContent: 'center',
    border: '1px solid blue'
}));

const AvatarBox = styled(Avatar) (() => ({
    margin: '1rem 0 1rem 0',
    height: '100px',
    width: '100px',
}))


const AvatarTitle = styled(Typography) (() =>({
    color: Colors.white,
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign:'center',
}));

const AvatarDescription = styled(Typography) (() =>({
    color: Colors.white,
    // fontSize: '16px',
    textAlign:'center',
    padding: '3px',
    fontFamily:Typography.fontFamily,

}));


export {AboutBox, AvatarBox, AvatarTitle, AvatarDescription}