import { List, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { AboutBox, AvatarBox, AvatarTitle, AvatarDescription } from '../../styles/about'
import Abdullah from '../../assests/avatar/abdullah3.jpeg'
import abdul_rehman from '../../assests/avatar/abdul_rehman.jpeg'
import Link from '@mui/material/Link';

const About = () => {

  const theme = useTheme()

  return (
    <Grid container spacing={3}>
        {
        aboutItem.map((item, index)=>{
            return (
                <Grid item sm={6} xs={12} key={index}>
                    <AboutBox key={index}>
                        <AvatarBox alt={item.alt} src={item.src}></AvatarBox>
                        <AvatarTitle 
                            sx={{
                                [theme.breakpoints.down("md")]:{
                                    fontSize:'1rem',
                                }
                            }}
                        >
                            {item.title} 
                        </AvatarTitle>
                        <List>
                            <AvatarDescription>{item.description}</AvatarDescription>
                        </List>
                        <Link style={{ textDecoration: 'none' }} target='_blank' href={`https://api.whatsapp.com/send?phone=${item.contact}`}>Whatsapp:{item.contact_show}</Link>
                    </AboutBox>
                </Grid>
            )
        })
        }
    </Grid>
  )
}

const aboutItem = [
    {
        src: Abdullah,
        alt: "Hafiz Muhammad Abdullah Cheema",
        title: "Hafiz Muhammad Abdullah Cheema",
        description: `This is Hafiz Muhammad Abullah Cheema. He has years of experience in Quran and Hadith teaching at various institutions, and online forms. He can teach Phonetics of the Holy Quran, Hadith Nabvi (PBUH), Tafseer of Quran, Arabic Grammar also. He did his graduation in Islamic and Abraic Studies from Jamia Naeemia Lahore Pakistan, and graduated in  History and Pakistan studies from Punjab University Lahore. Currently, He is serving as a lecturer at  JAMIA NAEEMIA LAHORE and PUNJAB UNIVERSITY also.`,
        contact: "923238440787",
        contact_show: " +923238440787"
    },
    {
        src: abdul_rehman,
        alt: "Hafiz Abdul Rehman Cheema",
        title: "Hafiz Abdul Rehman Cheema",
        description: `This is Hafiz Abdul Rehman Tayyab. He has years of experience in Quran and Hadith teaching at various institutions, and online forms. He can teach Phonetics of the Holy Quran, Hadith Nabvi (PBUH), Tafseer of Quran, Arabic Grammar etc. He did his graduation in Islamic and Arabic Studies from Jamia Naeemia Lahore Pakistan, and graduated in English Literature and Linguistics from NUML.`,
        contact: "923064554941",
        contact_show: " +923064554941"
    }
]

export default About