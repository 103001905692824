import './footer.css'
import {BsFacebook} from 'react-icons/bs'
import {FaInstagramSquare} from 'react-icons/fa'
import {FaLinkedin} from 'react-icons/fa'
import {BsYoutube} from 'react-icons/bs'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiOutlineWhatsApp} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <div className='footer-content'>
        <h3>Quran Seek</h3>
        <p>Contact Us</p>
        <ul class='socials'>
          <li><a href='https://m.facebook.com/story.php?story_fbid=pfbid0pp22BVf4YD417DkiD1sqTuLiAG6w9ZrDWM1kMeG6DkRpP4F2WR5rqXXm5oKP7Rgbl&id=100092205518479&sfnsn=scwspwa&mibextid=RUbZ1f' target={'_blank'}><BsFacebook  className='icon'/></a></li>
          <li><a href='https://twitter.com/quran_seek73598?t=oVuGr5FQN5mMoc_xaElqYQ&s=08' target={'_blank'}><AiOutlineTwitter className='icon'/></a></li>
          <li><a href='#' target={'_blank'}><FaInstagramSquare className='icon'/></a></li>
          <li><a href='https://chat.whatsapp.com/HBV4EhbwcWEEOHFvXZfBee' target={'_blank'}><AiOutlineWhatsApp className='icon'/></a></li>
          <li><a href='https://youtube.com/@QuranSeek' target={'_blank'}><BsYoutube className='icon'/></a></li>
          <li><a href='https://www.linkedin.com/in/quran-seek-60a9b6282' target={'_blank'}><FaLinkedin className='icon'/></a></li>
        </ul>
        <div class='footer-bottom'>
          <p>copyright &copy;2023 <a href='#'>quranSeekh</a></p>
        </div>
        <div class='footer-menu'>
          <ul class='f-menu'>
              <li><a href='/home'>Home</a></li>
              <li><a href='/courses'>Courses</a></li>
              <li><a href='/about'>About</a></li>
              <li><a href='/contact'>Contact Us</a></li>
          </ul>
          </div>
      </div>
    </footer>
  )
}

export default Footer